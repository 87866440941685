<template>
  <b-modal
    id="room-reservation-modal"
    hide-header
    hide-footer
    centered
    size="xl"
    @show="onShow"
    @hidden="closeModal"
  >
    <div class="modal-header">
      <div class="modal-title">
        <span>{{ modalTitle }}</span>
      </div>
      <span class="icon-box">
        <Close class="icon stroke" @click="closeModal" />
      </span>
    </div>
    <div class="around-content">
      <div class="card p-3 mb-3" v-if="showHeaderMessage">
        <p>{{ headerMessage.title }}</p>
        <span>{{ headerMessage.message }}</span>
      </div>
      <validation-observer v-slot="{ invalid }">
        <b-row>
          <b-col cols="6">
            <b-form-group>
              <validation-provider name="room_id" rules="required">
                <label for="room_id">Sala</label>
                <multiselect
                  id="room_id"
                  :value="roomSelected"
                  class="with-border"
                  placeholder="Selecionar"
                  label="label"
                  :allow-empty="false"
                  :show-labels="false"
                  @select="selectRoom"
                  :options="rooms"
                  :disabled="disabledFields"
                >
                  <template slot="caret">
                    <div class="chevron">
                      <ChevronDown />
                    </div>
                  </template>
                  <template slot="noResult"> Nenhuma sala encontrada </template>
                  <template slot="noOptions">
                    Nenhuma sala encontrada
                  </template>
                </multiselect>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="2">
            <b-form-group>
              <validation-provider name="date" rules="required">
                <label for="date">Data</label>
                <date-picker
                  id="date"
                  format="DD/MM/YYYY"
                  class="full"
                  v-model="form.date"
                  :disabled-date="disableDates"
                  placeholder="Selecionar"
                  :clearable="false"
                  :disabled="disabledFields"
                  @input="getScheduleConfig"
                />
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="2">
            <validation-provider name="start_time" rules="required">
              <TimeSelect
                id="start_time"
                v-model="form.start_time"
                :value="form.start_time"
                label="Horário inicial"
                :options="startTimeOptionsComputed"
                required
                :checkBusyTime="checkBusyTime"
                @input="timeInput($event, 'invalidStartTime', 'start_time')"
                :disabled="disabledFields"
              />
              <div v-if="invalidStartTime" class="custom-invalid-feedback">
                <Ellipsis>
                  Horário indisponível
                </Ellipsis>
              </div>
            </validation-provider>
          </b-col>

          <b-col cols="2">
            <validation-provider name="end_time" rules="required">
              <TimeSelect
                id="end_time"
                v-model="form.end_time"
                :value="form.end_time"
                label="Horário final"
                :options="endTimeOptionsComputed"
                required
                :checkBusyTime="checkBusyTime"
                @input="timeInput($event, 'invalidEndTime')"
                :disabled="disabledFields"
              />
              <div v-if="invalidEndTime" class="custom-invalid-feedback">
                <Ellipsis>
                  Horário indisponível
                </Ellipsis>
              </div>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row
          v-if="
            roomReservation?.professional_id ||
            !Object.keys(roomReservation).length
          "
        >
          <b-col cols="12">
            <b-form-group>
              <label id="professional_id">
                Profissional solicitante
                <span class="help">(opcional)</span>
              </label>
              <Autocomplete
                id="professional_id"
                :value="form.professional_id"
                placeholder="Selecionar"
                :options="professionals"
                @input="selectProfessional"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <validation-provider name="reason" rules="required">
              <b-form-group>
                <label for="reason">Justificativa</label>
                <TextArea id="reason" v-model="form.reason" />
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <div class="wrapper-button">
          <b-button
            v-if="roomReservation?.id"
            variant="outline-danger"
            size="lg"
            class="mr-auto"
            @click="deleteRoomReservation"
          >
            {{ deleteButtonTitle }}
          </b-button>
          <b-button
            variant="primary"
            :disabled="invalid || isDisabled"
            size="lg"
            @click="
              roomReservation?.id
                ? updateRoomReservation()
                : createRoomReservation()
            "
          >
            Salvar
          </b-button>
        </div>
      </validation-observer>
    </div>
  </b-modal>
</template>

<script>
import { getCurrentClinic } from '@/utils/localStorageManager'
import { isPastDate } from '@/utils/dateHelper'
import dateAndTimeModuleMixin from '@/mixins/dateAndTimeModuleMixin'

export default {
  name: 'RoomReservationModal',
  components: {
    Close: () => import('@/assets/icons/close.svg'),
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    TimeSelect: () => import('@/components/Schedule/TimeSelect'),
    TextArea: () => import('@/components/General/TextArea'),
    Autocomplete: () => import('../../Autocomplete.vue'),
    Ellipsis: () => import('@/components/General/Ellipsis')
  },
  props: {
    date: {
      type: Date,
      validator: value => !isPastDate(value)
    },
    roomId: String,
    roomReservation: {
      type: Object,
      default: () => ({})
    },
    rooms: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    clinic: getCurrentClinic(),
    professionals: [],
    form: {
      room_id: '',
      date: '',
      start_time: '',
      end_time: '',
      professional_id: '',
      reason: '',
      isReservation: true
    },
    invalidStartTime: false,
    invalidEndTime: false,
    // invalidPeriod: false
  }),
  mixins: [dateAndTimeModuleMixin('form', 'clinic')],
  computed: {
    roomSelected() {
      return this.rooms.find(room => room.id === this.form.room_id)
    },
    isDisabled() {
      return (!this.isEndGreaterThanStart)
    },
    modalTitle() {
      if (this.roomReservation?.professional_id) {
        return 'Reserva de sala'
      } else if (this.roomReservation?.id) {
        return 'Bloqueio de sala'
      }
      return 'Criar novo bloqueio/reserva'
    },
    deleteButtonTitle() {
      return this.roomReservation?.professional_id
        ? 'Excluir reserva'
        : 'Excluir bloqueio'
    },
    type() {
      return this.roomReservation?.professional_id ? 'Reserva' : 'Bloqueio'
    },
    disabledFields() {
      return Boolean(this.roomReservation?.id)
    },
    isEndGreaterThanStart() {
      return this.form.end_time > this.form.start_time
    },
    showHeaderMessage() {
      return this.form.professional_id || this.form.reason
    },
    headerMessage() {
      if (this.form.professional_id) {
        return {
          title: 'Reserva de sala',
          message:
            'Ao selecionar um profissional solicitante você estará criando uma reserva de sala para o profissional selecionado.'
        }
      }
      return {
        title: 'Bloqueio de sala',
        message:
          'Ao deixar de selecionar um profissional solicitante você estará criando um bloqueio de sala.'
      }
    }
  },
  methods: {
    async onShow() {
      await this.getData()
      this.form?.room_id && await this.getScheduleConfig()
    },
    closeModal() {
      this.clearForm()
      this.$bvModal.hide('room-reservation-modal')
      this.$emit('close-room-reservation-modal')
    },
    clearTimes() {
      if (this.roomReservation?.id)
        return

      this.$set(this.form, 'start_time', '')
      this.$set(this.form, 'end_time', '')
    },
    async selectRoom(value) {
      this.$set(this.form, 'room_id', value.id)
      this.clearTimes()
      await this.getScheduleConfig()
    },
    selectProfessional(value) {
      this.$set(this.form, 'professional_id', value)
      this.clearTimes()
    },
    async getData() {
      const isLoading = this.$loading.show()
      try {
        await Promise.all([this.getClinicDoctors(), this.getScheduleConfig()])
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    async getClinicDoctors() {
      try {
        const { data } = await this.api.getClinicDoctors(this.clinic.id)
        this.professionals = data.doctors.map(doctor => ({
          value: doctor.id,
          name: doctor.name,
          label: doctor.name,
        }))
      } catch (error) {
        throw new Error({
          message: 'Não foi possível carregar os profissionais da clínica'
        })
      }
    },
    async createRoomReservation() {
      // if (this.invalidPeriod) {
      //   this.$toast.warning('O período informado não está disponível pois possui agendamentos, reservas ou bloqueios!')
      //   return
      // }
      const isLoading = this.$loading.show()
      try {
        await this.api.createRoomReservation(this.form)
        this.$toast.success(`${this.type} criado com sucesso!`)
        this.$emit('reload-room-reservations')
        this.closeModal()
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    async updateRoomReservation() {
      // if (this.invalidPeriod) {
      //   this.$toast.warning('O período informado não está disponível pois possui agendamentos, reservas ou bloqueios!')
      //   return
      // }
      const isLoading = this.$loading.show()
      try {
        await this.api.updateRoomReservation(this.roomReservation.id, this.form)
        this.$toast.success(`${this.type} atualizado com sucesso!`)
        this.$emit('reload-room-reservations')
        this.closeModal()
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    async deleteRoomReservation() {
      const isLoading = this.$loading.show()
      try {
        await this.api.deleteRoomReservation(this.roomReservation.id)
        this.$toast.success(`${this.type} removido com sucesso!`)
        this.$emit('remove-room-reservation', this.roomReservation.id)
        this.closeModal()
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    async timeInput(value, invalidField, field) {

      // if (this.checkBusyTime(value)) {
      //   this[invalidField] = true
      //   return
      // }
      // this[invalidField] = false

      if (field === 'start_time' && (!this.form.end_time || this.form.end_time <= value)) {
        this.form.end_time = this.endTimeOptionsComputed.find(
          time => time.value > value
        ).value
      } 
      // Por enquanto vamos permitir agendar horários iguais
      // if (this.form.start_time && this.form.end_time && this.form.room_id && this.form.date) {
      //   try {
      //     await this.hasAppointmentOnPeriod(
      //       `${this.moment(this.form.date).format('YYYY-MM-DD')} ${
      //         this.form.start_time
      //       }`,
      //       `${this.moment(this.form.date).format('YYYY-MM-DD')} ${
      //         this.form.end_time
      //       }`
      //     )
      //   } catch (error) {
      //     this.$toast.error(error.message)
      //   }
      // }
    },
    async hasAppointmentOnPeriod(start, end) {
      try {
        const { data } = await this.api.hasAppointmentOnPeriod(
          this.clinic.id,
          this.form.room_id,
          start,
          end,
          {
            exceptions: this.roomReservation.id ? [this.roomReservation.id] : [],
          }
        )
        this.invalidPeriod = data
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    clearForm() {
      Object.keys(this.form).forEach(key => {
        this.form[key] = ''
      })
    }
  },
  watch: {
    date: {
      handler(value) {
        if (!value) return
        this.form.date = value
      },
      immediate: true
    },
    'form.date': {
      handler() {
        if (this.form.start_time && this.form.end_time)
          this.timeInput(this.form.start_time, 'invalidStartTime')
      }
    },
    roomId: {
      handler(value) {
        if (!value) return
        this.form.room_id = value
      }
    },
    roomReservation: {
      handler(value) {
        if (!value?.id) return
        this.form = {
          ...value,
          date: this.moment(value.date).toDate(),
          start_time: this.moment(value.start_time, 'HH:mm').format('HH:mm'),
          end_time: this.moment(value.end_time, 'HH:mm').format('HH:mm')
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss">
#room-reservation-modal {
  .modal-body {
    padding: 0 !important;
    margin: 0 !important;

    .modal-header {
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px !important;

      .modal-title {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: var(--type-active);
      }

      .icon {
        width: 25px;
        fill: var(--neutral-500);
      }
    }
  }

  .around-content {
    padding: 24px 24px 0 24px;

    .card {
      border: none;
      display: flex;
      background-color: #e5f9ff;

      p,
      span {
        font-size: min(1.2vw, 16px);
        color: var(--type-active);
      }
      p {
        font-weight: 700;
      }

      span {
        font-weight: 600;
      }
    }
  }

  .wrapper-button {
    width: 100%;
    display: flex;
    margin: 20px 0 !important;
    justify-content: flex-end;

    button {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
</style>
